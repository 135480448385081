import { Box } from '@tofu/shared/ui/atoms/box'
import { Link } from '@tofu/shop/ui/atoms/link'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Container } from '@tofu/shared/ui/atoms/container'
import { getFromMap } from '@tofu/shared/utils/get-from-map'

import { TSliceButton } from './slice-button.types'
import { containerMap } from './slice-button.constants'

export const SliceButton: TSliceButton = ({
  align,
  background,
  text,
  theme,
  link,
  size,
  variant,
  width
}) => {
  const maxWidth = getFromMap(containerMap, width)

  return (
    <Box bg={background}>
      <Container maxWidth={maxWidth} as='div' textAlign={align}>
        {/*  
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment/* 
          @ts-ignore */}
        <Link href={link?.url} passHref>
          <Button as='span' variant={variant} colorScheme={theme} size={size}>
            {text}
          </Button>
        </Link>
      </Container>
    </Box>
  )
}
